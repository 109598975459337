import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { usePostHog } from 'posthog-js/react'
import { useRouter } from 'next/router'

import { sessionQueryKey, sessionService } from 'lib/services/sessionService'
import { Session } from 'lib/models/session'
import { toast } from 'react-toastify'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'

const staleTime = 1000 * 60 * 5 // 5 minutes

export const useSession = (): {
    isLoading: boolean
    session: Session | null
    error: string | undefined
} => {
    const router = useRouter()
    const posthog = usePostHog()

    const { isLoading, error, data } = useQuery({
        queryKey: sessionQueryKey,
        queryFn: sessionService.getSession,
        staleTime,
    })

    const errorMessage = getErrorFromQuery(error, data)
    const session = data && !data.error ? data.accountData : null
    const appVersion = data && !data.error ? data.appVersion : ''

    useEffect(() => {
        if (!session?.user_id || !posthog) return

        posthog.identify(String(session.user_id), {
            email: session.email,
            company: session.company,
            role: session.role,
            account_id: session.account_id,
        })
    }, [
        session?.user_id,
        session?.email,
        session?.company,
        session?.role,
        session?.account_id,
        posthog,
    ])

    useEffect(() => {
        const storedAppVersion = window.localStorage.getItem('server-app-version')

        if (appVersion && storedAppVersion !== appVersion) {
            window.localStorage.setItem('version-update-needed', 'true')
            window.localStorage.setItem('server-app-version', appVersion)
        }
        // if no redirect needed, just return (example: already on /dashboard)
        // if session data not yet there (fetch in progress, logged in or not) then don't do anything yet
        if (isLoading || error) return

        if (errorMessage === 'Session has expired.') {
            toast.warn('Tu sesión ha expirado. Por favor inicia sesión de nuevo.')
        }

        if (!session) router.push('/login')
    }, [session, isLoading, error, router, appVersion, errorMessage])

    return { session, isLoading, error: errorMessage }
}
