import { addDays, differenceInDays } from 'date-fns'
import { Session } from 'lib/models/session'
import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useLocalStorage } from 'usehooks-ts'

type Props = {
    session: Session | null
}

type ExpiringOrgs = Record<number, string> // id to next_notify_at

export const useWarnExpiredOrgs = ({ session }: Props) => {
    const [value, setValue] = useLocalStorage<ExpiringOrgs>('warn-expiring-orgs', {})

    useEffect(() => {
        if (!session || !session.expiring_orgs?.length) return
        const currentDate = new Date()

        const newExpiringOrgs = value ? { ...value } : {}

        const alertMessage: string[] = []

        session.expiring_orgs.forEach(
            ({ organization_id: org_id, legal_name, display_name, expires_at }) => {
                const nextNotifyAt = value[org_id] ? new Date(value[org_id]) : undefined
                if (nextNotifyAt && currentDate < nextNotifyAt) return

                const expiresAtDate = new Date(expires_at)

                const dayDiff = differenceInDays(expiresAtDate, currentDate)
                const daysLeft =
                    expiresAtDate <= currentDate ? Math.min(-1, dayDiff) : dayDiff

                if (daysLeft <= -30) return

                const daysToNotify = daysLeft > 10 || daysLeft < -10 ? 2 : 1
                newExpiringOrgs[org_id] = addDays(currentDate, daysToNotify).toISOString()

                const daysLeftMessage =
                    daysLeft < 0
                        ? 'ya expiró'
                        : daysLeft === 0
                        ? 'se expira en menos de 24 horas'
                        : `se expira en menos de ${daysLeft + 1} días`

                alertMessage.push(
                    `El Certificados de Sello Digital de ${
                        display_name || legal_name
                    } ${daysLeftMessage}. 
                Actualiza el CSD para que no se interrumpa tu facturación.`
                )
            }
        )

        if (!alertMessage.length) return
        alertMessage.forEach((message) =>
            toast.warn(message, { autoClose: false, className: 'hello' })
        )

        setValue(newExpiringOrgs)
    }, [session, value, setValue])

    return null
}
