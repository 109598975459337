export const getErrorFromQuery = (
    error: any,
    data: { error?: string } | undefined
): string | undefined => {
    let message: string | undefined

    if (error) message = error.message
    if (!message && data?.error) message = data.error

    return message?.trim() || undefined
}
