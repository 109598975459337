import { FC, ReactNode, useState } from 'react'

import Link from 'next/link'
import { Logo } from 'ds/components'
import { LayoutNav } from './LayoutNav'

const year = new Date().getFullYear()

type Props = {
    isLoading?: boolean
    children: ReactNode
}

export const Layout: FC<Props> = ({ children, isLoading }) => {
    const [showMainNavOnMobile, setShowMainNavOnMobile] = useState(false)

    return (
        <div className="d-flex h-100p">
            <LayoutNav
                isLoading={isLoading}
                showMainNavOnMobile={showMainNavOnMobile}
                setShowMainNavOnMobile={setShowMainNavOnMobile}
            />
            <div className="overflow-y flex-fill">
                <div className="container-fluid pt-2 pb-1 d-md-none">
                    <div className="d-flex align-items-center">
                        <div className="mr-auto">
                            <Link href="/dashboard">
                                <Logo height={30} />
                            </Link>
                        </div>
                        <div
                            className="d-inline pointer"
                            onClick={() => setShowMainNavOnMobile(!showMainNavOnMobile)}
                        >
                            <ion-icon name="menu-sharp" style={{ fontSize: '28px' }} />
                        </div>
                    </div>
                </div>
                {children}
                <div className="inset-xl container-fluid center small neutral-600">
                    <p>© {year} Savio</p>
                </div>
            </div>
            {/* <AppUpdateBanner /> */}
        </div>
    )
}
