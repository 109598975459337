export enum Role {
    SUPERADMIN = 'superadmin',
    ADMIN = 'admin',
    USER = 'user',
    READ_ONLY = 'read-only',
    // These default to User now. Remove soon
    NO_BANK_ACCOUNTS = 'no-bank',
    NO_OUTGOING_BANK_TRANSACTIONS = 'no-outgoing',
}

export const ValidRoles = new Set(
    Object.values(Role).filter(
        (role) =>
            role !== Role.NO_BANK_ACCOUNTS &&
            role !== Role.NO_OUTGOING_BANK_TRANSACTIONS
    )
)
