export enum Permission {
    WRITE_CUSTOMER = 'customer:write',

    WRITE_SUBSCRIPTION = 'subscription:write',
    DELETE_SUBSCRIPTION = 'subscription:delete',

    WRITE_INVOICE = 'invoice:write',
    DELETE_INVOICE = 'invoice:delete',

    WRITE_PAYMENT = 'payment:write',
    DELETE_PAYMENT = 'payment:delete',

    READ_BANK_ACCOUNT = 'bank-account:read',
    READ_BANK_ACCOUNT_OUTGOING = 'bank-account:outgoing:read',
    READ_BANK_ACCOUNT_BALANCE = 'bank-account:balance:read',
}

export const PermissionDisplay: Record<Permission, string> = {
    [Permission.WRITE_CUSTOMER]: 'Crear/Actualizar/Borrar Clientes',
    [Permission.WRITE_SUBSCRIPTION]: 'Crear/Actualizar Cobros Recurrentes',
    [Permission.DELETE_SUBSCRIPTION]: 'Borrar/Archivar Cobros Recurrentes',
    [Permission.WRITE_INVOICE]: 'Crear/Actualizar Cobros',
    [Permission.DELETE_INVOICE]: 'Borrar Cobros',
    [Permission.WRITE_PAYMENT]: 'Crear Pagos',
    [Permission.DELETE_PAYMENT]: 'Borrar Pagos',
    [Permission.READ_BANK_ACCOUNT]: 'Acceso a Conciliación',
    [Permission.READ_BANK_ACCOUNT_OUTGOING]: 'Acceso a Cargos en Conciliación',
    [Permission.READ_BANK_ACCOUNT_BALANCE]: 'Acceso a Saldos en Conciliación',
}

export const AllPermissions = Object.values(Permission)
