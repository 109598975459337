import { Session } from 'lib/models/session'
import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/session'

export const sessionQueryKey = ['session']

export const sessionService = {
    login: async (body) => {
        const responseData = await makeApiRequest({ url, method: 'POST', body })
        localStorage.setItem('token', responseData.token)
        return responseData
    },
    getSession: async () =>
        makeApiRequest<{ accountData: Session; appVersion: string }>({ url }),
    logout: async () => makeApiRequest({ url, method: 'DELETE' }),
}
