import { toast } from 'react-toastify'

type OnErrorParams = {
    toastError?: string
}

export const mutationOnError = (params?: OnErrorParams) => {
    return (error: unknown) => {
        console.error('error', error)
        toast.error(params?.toastError || 'Algo salio mal.')
    }
}

type OnSuccessParams<T> = {
    toastSuccess?: string
    onSuccess?: (data: T, variables: any) => void
    onError?: (error: string) => void
}

export function mutationOnSuccess<T extends { error?: string }>(
    params?: OnSuccessParams<T>
) {
    return (data: T, variables: any) => {
        if (data.error) {
            if (params?.onError) {
                params.onError(data.error)
            } else {
                toast.error(data.error)
            }
            return
        }
        if (params?.toastSuccess) {
            toast.success(params.toastSuccess)
        }
        params?.onSuccess?.(data, variables)
    }
}
