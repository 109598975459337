import { FC, ReactNode } from 'react'

import { Logo } from 'ds/components'

type Props = {
    children: ReactNode
    isLoading?: boolean
}

export const OnboardingLayout: FC<Props> = ({ children }) => {
    return (
        <div className="container-xl">
            <div className="inset-y-lg">
                <Logo />
            </div>
            {children}
        </div>
    )
}
