import { FC } from 'react'
import { useQuery } from '@tanstack/react-query'

import { Banner, Spinner, ErrorBanner } from 'ds/components'
import { ImpersonateForm } from './ImpersonateForm'

import { accountService } from 'lib/services/accountService'
import { getErrorFromQuery } from 'lib/utils/getErrorFromQuery'
import { Session } from 'lib/models/session'

type Props = {
    session: Session
}

const staleTime = 1000 * 60 * 5 // 5 minutes

export const ImpersonationBanner: FC<Props> = ({ session }) => {
    const { error, isLoading, data } = useQuery({
        queryKey: ['superadmin', 'accounts'],
        queryFn: accountService.superadminGetAll,
        staleTime,
    })

    const errorMessage = getErrorFromQuery(error, data)
    if (isLoading) return <Spinner />
    if (errorMessage || !data) {
        return <ErrorBanner>Ha ocurrido un error: {errorMessage}</ErrorBanner>
    }

    const accounts = data.accounts ?? []

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 inset-md">
                    <Banner variant="secondary">
                        <div className="inline inline-md">
                            You are signed in to {session.company} as a {session.role}.
                        </div>
                        <div className="inline">
                            <ImpersonateForm accounts={accounts} session={session} />
                        </div>
                    </Banner>
                </div>
            </div>
        </div>
    )
}
