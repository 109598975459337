import { FC, useMemo } from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { SelectInput } from 'ds/components/SelectInput'

import { userService } from 'lib/services/userService'
import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { Session } from 'lib/models/session'

type Props = {
    session: Session
    accounts: any[]
}

export const ImpersonateForm: FC<Props> = ({ accounts, session }) => {
    const queryClient = useQueryClient()

    const currentAccount = useMemo(
        () => accounts.find((a) => a.account_id === session.account_id),
        [accounts, session.account_id]
    )

    const { mutate } = useMutation({ mutationFn: userService.putMe })

    const handleSubmit = (account_id) => {
        mutate(
            { account_id, user_id: session.user_id },
            {
                onError: mutationOnError(),
                onSuccess: mutationOnSuccess({
                    toastSuccess: 'Usuario actualizado.',
                    onSuccess: () => queryClient.invalidateQueries(),
                }),
            }
        )
    }

    return (
        <SelectInput
            className="inline inline-md w-200"
            value={currentAccount}
            onChange={(obj) => {
                if (!obj) return
                handleSubmit(obj.account_id)
            }}
            items={accounts}
            getItemId={(o) => o.account_id}
            itemToString={(o) => (o ? `${o.account_id} - ${o.company}` : '')}
        />
    )
}
