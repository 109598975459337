import { makeApiRequest } from 'lib/utils/apiUtils/makeApiRequest'

const url = '/users'

export const userService = {
    getMe: async ({ user_id } = {}) =>
        makeApiRequest({ url: '/user', queryParams: { user_id } }),
    putMe: async (body) =>
        makeApiRequest({ url: '/user', method: 'PUT', body }),
    getUsers: async () => makeApiRequest({ url }),
    getUser: async ({ user_id }) =>
        makeApiRequest({ url: `${url}/${user_id}` }),
    postUser: async (body) => makeApiRequest({ url, method: 'POST', body }),
    putUser: async (body) =>
        makeApiRequest({ url: `${url}/${body.user_id}`, method: 'PUT', body }),
    deleteUser: async ({ user_id }) =>
        makeApiRequest({ url: `${url}/${user_id}`, method: 'DELETE' }),
    sendResetPasswordEmail: async (body) =>
        makeApiRequest({ url: '/user-pw', method: 'POST', body }),
    resetPassword: async (body) =>
        makeApiRequest({ url: '/user-pw', method: 'PUT', body }),
}
